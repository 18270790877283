.dropdown__container {
    position: absolute;
    top: calc(100% + 10px);

    right: 0;
}

.profile__dropdown {
    width: 300px;
    max-width: calc(190px + 1.5vw);

    position: absolute;
    right: 0px;

    box-shadow: 0 0 0px 99999px rgba(0, 0, 0, 0.1) inset,
        0 0 0px 99999px rgba(0, 0, 0, 0.1);

    transition: all 0.3s ease-in-out;
}

.connected__wallets {
    cursor: default;
}

.network__container {
    position: relative;

    height: 2rem;
    width: 2rem;

    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    right: 0.5rem;
}

.network__icon {
    height: 18px;
    width: 18px;

    svg {
        width: 100%;
        height: 100%;

        max-height: 18px;
        max-width: 18px;

        fill: var(--f20);
    }
}

.network__item {
    display: flex;
    flex-direction: column;
}

.network__text {
    font-size: 10px;
    font-weight: 200;
    color: var(--f20);
}

.account__container {
    position: relative;
}

.change__account__text {
    position: absolute;
}
.dummy__dropdown{
    border-radius: 0;

    position: relative;
    padding: 0.5rem 0 0.5rem 1.8rem;

    border: 1px solid var(--f20);
    border-top: 0;
    background-color: var(--b100);

    color: var(--f40);
    text-align: left;
    font-size: 0.85rem;


    &:hover {
        background-color: var(--f10);
        color: var(--f100);
    }

    white-space: nowrap;

}