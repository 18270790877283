::-webkit-scrollbar {
    width: 10px;
    background-color: var(--b100);
}
  
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: var(--f10);
}

::-webkit-scrollbar-thumb:hover {
    background-color: var(--f40);
}

html {
    scroll-behavior: smooth;

    margin: 0;
    padding: 0;
    width: 100vw;
    height: 100vh;
}

body {
    margin: 0;
    padding: 0;

    overflow-x: hidden;
    
    color: var(--f100);
    background-color: var(--b100);
    transition: background-color #{$transition} ease-out, color #{$transition} ease-out;
}

* {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    font-family: 'Space Grotesk', 'OCR A Extended', monospace;
}

h1, h2, h3 {
    font-family: 'OCR A Extended';
    font-weight: 400;

    margin: 0;
}

h1 {
    font-size: 36px;
} h2 {
    font-size: 24px;
} h3 {
    font-size: 16px;
} p {
    font-size: 12px;
    margin: 0;
}

a {
    color: var(--f100);
}

hr {
    width: 100%;
    border: none;
    border-bottom: 1px solid var(--f40);
}

ul li {
    list-style: none;
}

.page__container__main {
    width: 100%;
    padding-top: 150px;
    padding-bottom: calc(64px + 1em);
}

.fullscreen.fullscreen-enabled {
    display: flex;
    align-items: center;
    justify-content: center;
}

.artist-label {
    display: inline-block;
    font-size: larger;
    line-height: 1;
    text-align: left;
    white-space: nowrap;
    border-radius: 0.25rem;
    color: var(--f40);
    max-width: 100%;
    margin-bottom: 2rem;
}