.event__title {
    padding: 0.5rem;
    padding-bottom: 0;
}

.network {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    margin-bottom: 2rem;
}

.network__dropdown {
    width: calc(30vw);
    max-width: 250px;
}

.event_description {
    margin-bottom: 4rem;
}

.event_description p {
    font-size: 1rem !important;
}

.event_infoDisplay {
    display: flex;
    column-gap: 2rem;
    row-gap: 2rem;
    flex-direction: column;
    align-items: center;
}

.event_infoDisplay div:not(.event_description) {
    width: 100%;
}

.event_infoDisplay img {
    max-width: 100%;
}

@media (min-width: 991px) {
    .event_infoDisplay {
        flex-direction: row-reverse;
    }

    .event_infoDisplay div:not(.event_description) {
        width: 50%;
    }
}

@media (min-width: 1400px) {
    .event_description {
        max-width: 50%;
    }
}