@import '../../../../styles/variables.scss';

.avatar__container {
    width: fit-content;
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;   
}

.avatar {
    position: relative;

    height: 45px;
    width: 45px;

    border-radius: 50%;

    box-shadow: 
        0 0 0 1px var(--b80),
        0 0 0 2px var(--f10);
    
    transition: calc($transition / 1.5) ease-out;
    
    &:hover {
        box-shadow: 
            0 0 0 1px var(--b80),
            0 0 0 3px var(--f20);
    }

    z-index: 1;
}

.logo__container {
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    width: fit-content;
}

.logo {
    border-right: 1px solid var(--f20);

    width: 56px;
    height: 56px;

    path {
        fill: var(--f90);
    }
}

.markup {
    margin: 0 1em;

    display: flex;
    flex-direction: column;

    span {
        font-size: .8em;
        font-family: 'OCR A Extended';

        &:first-of-type {
            font-size: 1.2em;
        }
    }
}

.log__in {
    background-color: var(--b100);
    border: 1px solid var(--f40);

    color: var(--f60);

    &:hover {
        background-color: var(--f10);
        color: var(--f100);
    }
}

.dropdown__login__menu__container{
    visibility: hidden;
}

.dropdown__login__menu__button{
    visibility: visible;
}
@media (max-width: 760px) {
    .dropdown__login__menu__container{
        visibility: visible;
    
        left: 20rem;
        position:fixed;
    }
    .dropdown__login__menu__button{
        visibility: hidden;
    }
}

